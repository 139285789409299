<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">
                {{ $t('elearning_tim.circular_wise_evaluation') }} {{ $t('globalTrans.search') }}
              </h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="mobile"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.mobile')}}
                        </template>
                        <b-form-input
                          id="mobile"
                          name="mobile"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          v-model="search.mobile"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_tim.circular_wise_evaluation') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button variant="primary" @click="pdfDownload" class="mr-2">
                        {{  $t('globalTrans.download') }}
                      </b-button>
                      <b-button variant="primary" @click="pdfExport" class="mr-2">
                        {{  $t('globalTrans.print') }}
                      </b-button>
                      <router-link class="btn btn_add_new" :to="`training-course-evaluation`">
                        {{ $t('externalTraining.course_evaluation') }} {{ $t('globalTrans.list') }}
                      </router-link>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                          <b-row>
                            <b-table-simple v-if="detailsData" borderless style="width:100%; margin-left:20px;">
                              <b-tr>
                                <b-td style="width:18%; margin:1px;padding: 1px;">{{$t('elearning_config.fiscal_year')}}</b-td>
                                <b-td style="width:2%; margin:1px;padding: 1px;">{{ ':' }}</b-td>
                                <b-td style="width:80%; margin:1px;padding: 1px;">{{ $i18n.locale === 'bn' ? detailsData.fiscal_year_bn : detailsData.fiscal_year }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-td style="width:18%; margin:1px;padding: 1px;">{{$t('elearning_config.organization')}}</b-td>
                                <b-td style="width:2%; margin:1px;padding: 1px;">{{ ':' }}</b-td>
                                <b-td style="width:80%; margin:1px;padding: 1px;">{{ $i18n.locale === 'bn' ? detailsData.org_bn : detailsData.org }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-td style="width:18%; margin:1px;padding: 1px;">{{$t('elearning_config.training_title')}}</b-td>
                                <b-td style="width:2%; margin:1px;padding: 1px;">{{ ':' }}</b-td>
                                <b-td style="width:80%; margin:1px;padding: 1px;">{{ $i18n.locale === 'bn' ? detailsData.training_title_bn : detailsData.training_title }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-td style="width:18%; margin:1px;padding: 1px;">{{$t('elearning_iabm.circular_memo_no')}}</b-td>
                                <b-td style="width:2%; margin:1px;padding: 1px;">{{ ':' }}</b-td>
                                <b-td style="width:80%; margin:1px;padding: 1px;">{{ detailsData.circular_memo_no }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-td style="width:18%; margin:1px;padding: 1px;">{{$t('elearning_iabm.batch_no')}}</b-td>
                                <b-td style="width:2%; margin:1px;padding: 1px;">{{ ':' }}</b-td>
                                <b-td style="width:80%; margin:1px;padding: 1px;">{{ $i18n.locale === 'bn' ? detailsData.batch_name_bn : detailsData.batch_name }}</b-td>
                              </b-tr>
                            </b-table-simple>
                          </b-row>
                          <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                  <template v-slot:cell(index)="data">
                                  {{ $n(data.index + 1) }}
                                  </template>
                                  <template v-slot:cell(designation)="data">
                                    <span>{{ getDesignationData(data.item, 'admin') }}</span>
                                  </template>
                                  <template v-slot:cell(workplace)="data">
                                    <span>{{ getWorkPlace(data.item, 'admin') }}</span>
                                  </template>
                                  <template v-slot:cell(organization)="data">
                                    <span>{{ getOrgData(data.item, 'admin') }}</span>
                                  </template>
                                  <template v-slot:cell(evaluation)="data">
                                    <span>{{ parseInt(data.item.evaluation) === parseInt(1) ? $t('globalTrans.yes') : $t('globalTrans.no') }}</span>
                                  </template>
                                  <!-- <template v-slot:cell(mobile)="data">
                                    {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                                  </template> -->
                                  <template v-slot:cell(action)="data">
                                      <router-link v-if="parseInt(data.item.evaluation) === parseInt(1)" :to="`training-course-evaluation-details?circularMemoNo=${data.item.circular_memo_no}&batch_no=${data.item.batch_no}&personal_info_id=${data.item.personal_info_id}`" :title="$t('globalTrans.view')" :class="'btn_table_action table_action_view'"><i class="fas fa-eye"></i></router-link>
                                  </template>
                                </b-table>
                            </b-col>
                          </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { traineeCourseWiseEvaluationList, circularList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
// import Payment from './Payment'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
        return {
          search: {
            circular_memo_no: this.$route.query.circularMemoNo,
            mobile: ''
          },
          circularLoading: false,
          detailsData: {},
          workplace_data: '',
          allBatchListData: [],
          trainingTypeList: [],
          batchList: [],
          circularList: [],
          trainingCategoryList: [],
          trainingTitleList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
        }
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_tim.bill_payment_entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
          const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            { label: this.$t('globalTrans.name'), class: 'text-center' },
            // { label: this.$t('globalTrans.mobile'), class: 'text-center' },
            { label: this.$t('elearning_tim.designation'), class: 'text-center' },
            { label: this.$t('elearning_tim.workplace'), class: 'text-center' },
            { label: this.$t('globalTrans.organization'), class: 'text-center' },
            { label: this.$t('elearning_tim.evaluation_status'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'name_bn' },
              // { key: 'mobile' },
              { key: 'designation' },
              { key: 'workplace' },
              { key: 'organization' },
              { key: 'evaluation' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'name' },
              // { key: 'mobile' },
              { key: 'designation' },
              { key: 'workplace' },
              { key: 'organization' },
              { key: 'evaluation' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      },
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      }
    },
    async created () {
      this.loadData()
      this.search = Object.assign({}, this.search, {
          fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.search.org_id = this.$store.state.Auth.authUser.org_id
      }
    },
    mounted () {
        core.index()
    },
    watch: {
    },
    methods: {
      getWorkPlace (item, type) {
          if (parseInt(item.profession_type) === 1 && item.not_here_office === 0) {
            if (item.office_mail_address !== null) {
              // return ',' + this.getOfficeName(item.office_id) + ' ' + this.getOfficeAddress(item.office_id)
              this.workplace_data = this.getOfficeName(item.office_id, type) + ', ' + (this.$i18n.locale === 'bn' ? item.office_mail_address_bn : item.office_mail_address)
              return this.getOfficeName(item.office_id, type) + ', ' + (this.$i18n.locale === 'bn' ? item.office_mail_address_bn : item.office_mail_address)
            } else {
              this.workplace_data = this.getOfficeName(item.office_id, type)
              return this.getOfficeName(item.office_id, type)
            }
          } else if (parseInt(item.profession_type) === 1 && item.not_here_office === 1) {
              this.workplace_data = ((this.$i18n.locale === 'bn' ? item.office_name_bn : item.office_name))
              return ((this.$i18n.locale === 'bn' ? item.office_name_bn : item.office_name))
          } else {
              this.workplace_data = (this.$i18n.locale === 'bn' ? item.profession_other_org_name_bn : item.profession_other_org_name)
              return ((this.$i18n.locale === 'bn' ? item.profession_other_org_name_bn : item.profession_other_org_name))
          }
      },
      getOfficeName (id, type) {
          let office
          if (type === 'admin') {
              office = this.$store.state.commonObj.officeList.find(item => item.value === id)
          }
          if (type === 'panel') {
              office = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(item => item.value === id)
          }
          if (this.$i18n.locale === 'bn') {
              return office !== undefined ? office.text_bn : ''
          } else {
              return office !== undefined ? office.text_en : ''
          }
      },
      getDesignationData (item, type) {
        if (parseInt(item.not_here_designation) === 0 && parseInt(item.profession_type) === 1) {
          let desigObj
          if (type === 'admin') {
            desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === item.profession_designation_id)
          }
          if (type === 'panel') {
            desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === item.profession_designation_id)
          }
          if (typeof desigObj !== 'undefined') {
            this.designationName = this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
            return this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
          } else {
            return ''
          }
        } else {
          this.designationName = this.$i18n.locale === 'en' ? item.profession_designation_en : item.profession_designation_bn
          return this.$i18n.locale === 'en' ? item.profession_designation_en : item.profession_designation_bn
        }
      },
      getOrgData (item, type) {
        if (item.profession_org_id && parseInt(item.profession_type) === 1) {
          let orgObj
          if (type === 'admin') {
            orgObj = this.$store.state.commonObj.organizationProfileList.find(designation => designation.value === item.profession_org_id)
          }
          if (type === 'panel') {
            orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(designation => designation.value === item.profession_org_id)
          }
          if (typeof orgObj !== 'undefined') {
            this.organizationName = this.$i18n.locale === 'en' ? orgObj.text_en : orgObj.text_bn
            return this.$i18n.locale === 'en' ? orgObj.text_en : orgObj.text_bn
          } else {
            return ''
          }
        } else {
          this.organizationName = this.$i18n.locale === 'en' ? item.profession_other_org_name : item.profession_other_org_name_bn
          return this.$i18n.locale === 'en' ? item.profession_other_org_name : item.profession_other_org_name_bn
        }
      },
      getTypeList (orgId) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      },
      batchInfo () {
        const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.search.training_title_id))
        const batchList = []
        batchData.map((obj, index) => {
          if (obj.batch_no) {
            const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
            if (typeof isThere !== 'undefined') {
              batchList.push(isThere)
            }
          }
        })
        this.batchList = batchList
        if (this.batchList.length === 1) {
          this.search.batch_no = 1
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id,
          training_type_id: this.search.training_type_id,
          training_category_id: this.search.training_category_id,
          training_title_id: this.search.training_title_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          this.allBatchListData = result.batchList
          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      async searchData () {
          this.loadData()
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, traineeCourseWiseEvaluationList, params).then(response => {
              if (response.success) {
                  this.getDetailsDataList(response.data[0])
                  this.$store.dispatch('setList', this.getCustomDataList(response.data))
                  this.paginationData(response.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const batchObj = this.allBatchList.find(batch => batch.value === parseInt(item.batch_no))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch_name = batchObj.text_en
            batchData.batch_name_bn = batchObj.text_bn
          } else {
            batchData.batch_name = ''
            batchData.batch_name_bn = ''
          }
          return Object.assign({}, item, orgData, fiscalYearData, trainingTitleData, batchData)
        })
        return listData
      },
      getDetailsDataList (item) {
        this.detailsData.circular_memo_no = item.circular_memo_no
        this.detailsData.org_id = item.org_id
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
        if (typeof orgObj !== 'undefined') {
          this.detailsData.org = orgObj.text_en
          this.detailsData.org_bn = orgObj.text_bn
        } else {
          this.detailsData.org = ''
          this.detailsData.org_bn = ''
        }
        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
        if (typeof fiscalYearObj !== 'undefined') {
          this.detailsData.fiscal_year = fiscalYearObj.text_en
          this.detailsData.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          this.detailsData.fiscal_year = ''
          this.detailsData.fiscal_year_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
        if (typeof trainingTitleObj !== 'undefined') {
          this.detailsData.training_title = trainingTitleObj.text_en
          this.detailsData.training_title_bn = trainingTitleObj.text_bn
        } else {
          this.detailsData.training_title = ''
          this.detailsData.training_title_bn = ''
        }
        const batchObj = this.allBatchList.find(batch => batch.value === parseInt(item.batch_no))
        if (typeof batchObj !== 'undefined') {
          this.detailsData.batch_name = batchObj.text_en
          this.detailsData.batch_name_bn = batchObj.text_bn
        } else {
          this.detailsData.batch_name = ''
          this.detailsData.batch_name_bn = ''
        }
      },
      async pdfExport () {
        const search = {
          role: this.$store.state.Auth.activeRoleId,
          circular_memo_no: this.detailsData.circular_memo_no,
          org_id: this.detailsData.org_id,
          fiscal_year: this.detailsData.fiscal_year,
          fiscal_year_bn: this.detailsData.fiscal_year_bn,
          org: this.detailsData.org,
          org_bn: this.detailsData.org_bn,
          training_title: this.detailsData.training_title,
          training_title_bn: this.detailsData.training_title_bn,
          batch_name: this.detailsData.batch_name,
          batch_name_bn: this.detailsData.batch_name_bn
        }
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, traineeCourseWiseEvaluationList, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      async pdfDownload () {
        const search = {
          role: this.$store.state.Auth.activeRoleId,
          circular_memo_no: this.detailsData.circular_memo_no,
          org_id: this.detailsData.org_id,
          fiscal_year: this.detailsData.fiscal_year,
          fiscal_year_bn: this.detailsData.fiscal_year_bn,
          org: this.detailsData.org,
          org_bn: this.detailsData.org_bn,
          training_title: this.detailsData.training_title,
          training_title_bn: this.detailsData.training_title_bn,
          batch_name: this.detailsData.batch_name,
          batch_name_bn: this.detailsData.batch_name_bn
        }
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, traineeCourseWiseEvaluationList, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        // Create a temporary link element
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        // Set the file name for the download
        link.download = 'cirCularWiseTrainee.pdf'
        // Trigger the download
        document.body.appendChild(link)
        link.click()
        // Clean up
        document.body.removeChild(link)
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    }
}
</script>
